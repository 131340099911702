#landing-bg {
    color: aliceblue;
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./circuit.PNG') center center no-repeat;
    background-size: cover;

    .landing-text-container {
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);

        .landing-text {
            display: flex;
            letter-spacing: -1px;
            flex-direction: column;
            width: 100%;
            align-items: center;
            padding-top: 200px;
            color: #f8f9fa;
    
            h1 {
                font-size: 64px;
                margin-bottom: 20px;
            }
    
            p {
                text-align: center;
                font-size: 28px;
                margin-bottom: 30px;

                span {
                    color: skyblue;
                    border-bottom: 2px solid skyblue;
                }
            }

            button {
                border: none;
                color: aliceblue;
                background-color: #15263d;
                box-shadow: 0 0 0 4px rgba(21, 38, 61, 0.6);
                padding: 15px 40px;
                border-radius: 10px;
                font-size: 26px;
                font-weight: 500;
                transition: 300ms;
                letter-spacing: -1px;

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 0 5px rgba(52, 57, 63, 0.6);
                }
            }
        }
    }
}