#landing-bg {
  color: aliceblue;
  width: 100%;
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url("./circuit.PNG") center center no-repeat;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("./circuit.PNG") center center no-repeat;
  background-size: cover;
}

#landing-bg .landing-text-container {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

#landing-bg .landing-text-container .landing-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: -1px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 200px;
  color: #f8f9fa;
}

#landing-bg .landing-text-container .landing-text h1 {
  font-size: 64px;
  margin-bottom: 20px;
}

#landing-bg .landing-text-container .landing-text p {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}

#landing-bg .landing-text-container .landing-text p span {
  color: skyblue;
  border-bottom: 2px solid skyblue;
}

#landing-bg .landing-text-container .landing-text button {
  border: none;
  color: aliceblue;
  background-color: #15263d;
  -webkit-box-shadow: 0 0 0 4px rgba(21, 38, 61, 0.6);
          box-shadow: 0 0 0 4px rgba(21, 38, 61, 0.6);
  padding: 15px 40px;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  letter-spacing: -1px;
}

#landing-bg .landing-text-container .landing-text button:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 5px rgba(52, 57, 63, 0.6);
          box-shadow: 0 0 0 5px rgba(52, 57, 63, 0.6);
}
