.dashboard-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: #172235;
  background-size: cover;
  padding-bottom: 100px;
}

.dashboard-container h2 {
  color: aliceblue !important;
}

.dashboard-container .header-greeting {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  width: 100%;
  height: 250px;
}

.dashboard-container .header-greeting h1, .dashboard-container .header-greeting h4 {
  color: aliceblue;
}

.dashboard-container .new-course-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 36px;
}

.dashboard-container .new-course-card-container .course-card {
  width: 250px;
  height: 300px;
  -webkit-box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: 300ms;
  transition: 300ms;
  margin: 0 10px;
  padding: 10px 20px;
}

.dashboard-container .new-course-card-container .course-card:hover {
  cursor: pointer;
  text-shadow: 0 0 10px green;
  -webkit-box-shadow: 0 0px 30px 0 rgba(255, 255, 255, 0.5);
          box-shadow: 0 0px 30px 0 rgba(255, 255, 255, 0.5);
}

.dashboard-container .new-course-card-container .course-card .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-container .new-course-card-container .course-card .top h4 {
  color: aliceblue;
  font-weight: 700;
  font-size: 30px;
  margin-top: 26px;
  text-shadow: 0 0px 2px black;
}

.dashboard-container .new-course-card-container .course-card .top .white-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 200px;
  margin-top: 44px;
  -webkit-box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.dashboard-container .new-course-card-container .course-card .top .white-circle i {
  text-shadow: 0 1px 1px black;
}

.dashboard-container .new-course-card-container .course-card .top .white-circle .fa-html5 {
  color: chocolate;
}

.dashboard-container .new-course-card-container .course-card .top .white-circle .fa-css3-alt {
  color: #274de4;
}

.dashboard-container .new-course-card-container .course-card .top .white-circle .fa-js {
  color: #efd81d;
}

.dashboard-container .new-course-card-container .course-card .top .white-circle .fa-react {
  color: #5ed3f3;
}

.dashboard-container .new-course-card-container .course-card .bottom {
  margin-top: 24px;
  text-align: center;
  color: aliceblue;
  font-weight: 700;
  text-shadow: 0 0px 3px black;
}

.dashboard-container .courses .courses-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 36px;
}

.dashboard-container .courses .courses-container .course-card {
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 300px;
  width: 200px;
  -webkit-box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.dashboard-container .courses .courses-container .course-card:hover {
  cursor: pointer;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.dashboard-container .courses .courses-container .course-card .lock-svg {
  position: absolute;
  width: 90px;
  height: 90px;
  z-index: 2;
  fill: #cecece;
  stroke: black;
}

.dashboard-container .courses .courses-container .course-card .course-card-content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.dashboard-container .courses .courses-container .course-card .course-card-content-container .i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  background-color: #f8f9fa;
  width: 100px;
  height: 100px;
  border-radius: 200px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1), inset 0 0 30px 0 rgba(0, 0, 0, 0.35);
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1), inset 0 0 30px 0 rgba(0, 0, 0, 0.35);
}

.dashboard-container .courses .courses-container .course-card .course-card-content-container p {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #f8f9fa;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .course-card .course-card-content-container .lock {
  position: absolute;
}

.dashboard-container .courses .courses-container .course-card .course-card-content-container .lock .lock-svg {
  position: relative;
  top: 10px;
  z-index: 2;
}

.dashboard-container .courses .courses-container .gs {
  background-color: #000;
}

.dashboard-container .courses .courses-container .gs i {
  font-size: 60px;
  color: #000;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .html {
  background-color: chocolate;
}

.dashboard-container .courses .courses-container .html i {
  font-size: 72px;
  color: #d2691e;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .css {
  background-color: #264de4;
}

.dashboard-container .courses .courses-container .css i {
  font-size: 72px;
  color: #264de4;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .js {
  background-color: gold;
}

.dashboard-container .courses .courses-container .js i {
  font-size: 72px;
  color: gold;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .react {
  background-color: aqua;
  color: black;
}

.dashboard-container .courses .courses-container .react i {
  font-size: 72px;
  color: aqua;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 1px black);
          filter: drop-shadow(0 0 1px black);
}

.dashboard-container .courses .courses-container .react.gated {
  -webkit-filter: blur(2px);
          filter: blur(2px);
  border-radius: 10px;
  width: 200px;
  height: 300px;
}

.dashboard-container .upgrade-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #69af86;
  border-bottom: 1px dotted #69af86;
  width: 70px;
  margin: 5px auto;
}

.dashboard-container .upgrade-link .upgrade-svg {
  height: 14px;
  width: 14px;
  padding-left: 2px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.dashboard-container .upgrade-link:hover {
  cursor: pointer;
}

.dashboard-container h1 {
  letter-spacing: -1px;
  padding-top: 120px;
  margin-bottom: 8px;
  font-size: 44px;
}

.dashboard-container h2 {
  margin-top: 45px;
  letter-spacing: -0.5px;
}

.dashboard-container h4 {
  font-size: 18px;
  letter-spacing: -0.2px;
  margin-bottom: 2px;
  font-weight: 500;
}

.dashboard-container h1, .dashboard-container h2, .dashboard-container h4 {
  text-align: center;
  color: #212529;
}
