.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background-color: #0e1a2a;
    color:aliceblue;
    width: 100%;
    height: 80px;
    z-index: 500;

    a {
        text-decoration: none;
    }

    .links {

        a {
            margin: 0 10px;
            color:rgba(255, 255, 255, 0.8);
            text-transform: uppercase;
            font-weight: 500;
            transition: 300ms;
            width: 100%;

            &:hover {
                color:aliceblue;
            }
        }
    }

    .nav-btns {
        display: flex;
    }

    
    .account-btn {
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: 8px 14px;
        margin-right: 20px;
        border: none;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.10);
        color: #f8f9fa;
        text-decoration: none;
        letter-spacing: -0.5px;
        box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
        transition: 300ms;

        &:hover {
            transform: scale(1.01);
            box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
            cursor: pointer;
        }

        .account-btn-svg {
            width: 16px;
            height: 16px;
            padding-left: 5px;
        }
    }

    .login-account-btn {
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: 8px 14px;
        margin-right: 20px;
        border: none;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.6);
        color: #0e1a2a;
        text-decoration: none;
        letter-spacing: -0.5px;
        box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
        transition: 300ms;

        &:hover {
            transform: scale(1.01);
            box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
            cursor: pointer;
            // background-color:#0e1a2a;
            // color: rgba(255, 255, 255, 0.8);
        }

        .login-account-btn-svg {
            width: 16px;
            height: 16px;
            padding-left: 5px;
        }
    }



    .nav-svg {
        width: 40px;
        height: 40px;
        transform: rotate(45deg);
        stroke: aliceblue;
        fill: aliceblue;
        padding: 0 10px;
    }

    .brand {
        display: flex;
        align-items: center;
        line-height: 1;
        font-weight: 500;
        padding-left: 32px;
        font-size: 28px;
        color: #f8f9fa;

        &:hover {
            cursor: pointer;
        }

        .brand-svg {
            padding: 0 5px;
            width: 38px;
            height: 38px;
        }
    }
}