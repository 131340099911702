.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  background-color: #0e1a2a;
  color: aliceblue;
  width: 100%;
  height: 80px;
  z-index: 500;
}

.navbar a {
  text-decoration: none;
}

.navbar .links a {
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: 300ms;
  transition: 300ms;
  width: 100%;
}

.navbar .links a:hover {
  color: aliceblue;
}

.navbar .nav-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar .account-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  padding: 8px 14px;
  margin-right: 20px;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
  text-decoration: none;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
          box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.navbar .account-btn:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  -webkit-box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
          box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
  cursor: pointer;
}

.navbar .account-btn .account-btn-svg {
  width: 16px;
  height: 16px;
  padding-left: 5px;
}

.navbar .login-account-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  padding: 8px 14px;
  margin-right: 20px;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #0e1a2a;
  text-decoration: none;
  letter-spacing: -0.5px;
  -webkit-box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
          box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 1px 10px 2px black;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.navbar .login-account-btn:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  -webkit-box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
          box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.8), 2px 4px 10px 2px black;
  cursor: pointer;
}

.navbar .login-account-btn .login-account-btn-svg {
  width: 16px;
  height: 16px;
  padding-left: 5px;
}

.navbar .nav-svg {
  width: 40px;
  height: 40px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  stroke: aliceblue;
  fill: aliceblue;
  padding: 0 10px;
}

.navbar .brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
  font-weight: 500;
  padding-left: 32px;
  font-size: 28px;
  color: #f8f9fa;
}

.navbar .brand:hover {
  cursor: pointer;
}

.navbar .brand .brand-svg {
  padding: 0 5px;
  width: 38px;
  height: 38px;
}
