* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

.container {
  width: 100%;
}

.hljs {
  background: #333;
  color: #fff;
}

.hljs-doctag, .hljs-meta-keyword, .hljs-name, .hljs-strong {
  font-weight: 700;
}

.hljs-code, .hljs-emphasis {
  font-style: italic;
}

.hljs-section, .hljs-tag {
  color: #62c8f3;
}

.hljs-selector-class, .hljs-selector-id, .hljs-template-variable, .hljs-variable {
  color: #ade5fc;
}

.hljs-meta-string, .hljs-string {
  color: #a2fca2;
}

.hljs-attr, .hljs-quote, .hljs-selector-attr {
  color: #7bd694;
}

.hljs-tag .hljs-attr {
  color: inherit;
}

.hljs-attribute, .hljs-title, .hljs-type {
  color: #ffa;
}

.hljs-number, .hljs-symbol {
  color: #d36363;
}

.hljs-bullet, .hljs-template-tag {
  color: #b8d8a2;
}

.hljs-built_in, .hljs-keyword, .hljs-literal, .hljs-selector-tag {
  color: #fcc28c;
}

.hljs-code, .hljs-comment, .hljs-formula {
  color: #888;
}

.hljs-link, .hljs-regexp, .hljs-selector-pseudo {
  color: #c6b4f0;
}

.hljs-meta {
  color: #fc9b9b;
}

.hljs-deletion {
  background: #fc9b9b;
  color: #333;
}

.hljs-addition {
  background: #a2fca2;
  color: #333;
}

.hljs-subst {
  color: #fff;
}

.hljs a {
  color: inherit;
}

.hljs a:focus, .hljs a:hover {
  color: inherit;
  text-decoration: underline;
}

.hljs mark {
  background: #555;
  color: inherit;
}
