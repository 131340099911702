.gs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.gs-container .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 85px;
  color: #343a40;
  width: 100%;
}

.gs-container .content h1 {
  font-size: 44px;
  margin-bottom: 20px;
  letter-spacing: -1px;
  text-align: center;
}

.gs-container .content p {
  line-height: 1.7;
  font-weight: 500;
}

.gs-container .content p .p-link {
  text-decoration: none;
  color: royalblue;
  border-bottom: 1px dotted royalblue;
}

.gs-container .content p img {
  width: 100%;
  height: auto;
  margin: 10px 0;
}

.gs-container .content .gs-lessons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
      grid-template-columns: 1fr 4fr;
  gap: 8px;
  width: 100%;
  height: 450px;
}

.gs-container .content .gs-lessons .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #f8f9fa;
  height: 532px;
  border: 3px solid #343a40;
}

.gs-container .content .gs-lessons .menu h2 {
  text-align: center;
  border-bottom: 2px solid #343a40;
}

.gs-container .content .gs-lessons .menu ul.menu-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: white;
  border-left: 4px solid #f8f9fa;
  border-right: 4px solid #f8f9fa;
  border-top: 2px solid #343a40;
}

.gs-container .content .gs-lessons .menu ul.menu-track .active {
  background-color: #1e2c45;
  color: #e9ecef;
}

.gs-container .content .gs-lessons .menu ul.menu-track .li-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 5px 0;
  -webkit-box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.gs-container .content .gs-lessons .menu ul.menu-track .li-item:hover {
  cursor: pointer;
}

.gs-container .content .gs-lessons .menu ul.menu-track .li-item .completed {
  width: 26px;
  height: 26px;
  padding-right: 10px;
  stroke: #2f9e44;
}

.gs-container .content .gs-lessons .menu ul.menu-track .li-item .not-completed {
  width: 26px;
  height: 26px;
  padding-right: 10px;
  stroke: #fab005;
}

.gs-container .content .gs-lessons .menu ul.menu-track .li-item li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 14px;
  height: 50px;
  padding-left: 10px;
  z-index: 5;
}

.gs-container .content .gs-lessons .lesson-container {
  background-color: #f8f9fa;
  border: 3px solid #1e2c45;
}

.gs-container .content .gs-lessons .lesson-container .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 1100px;
  height: 500px;
  padding: 16px 50px;
  margin: 0 auto;
  overflow-y: scroll;
}

.gs-container .content .gs-lessons .lesson-container .content iframe {
  margin: 0 auto;
  width: 100%;
}

.gs-container .content .gs-lessons .lesson-container .content pre {
  background-color: #1e2c45 !important;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
}

.gs-container .content .gs-lessons .lesson-container .content pre code {
  white-space: pre;
  margin: 10px 0;
  color: #e9ecef;
  background-color: #1e2c45;
  width: 100%;
}

.gs-container .content .gs-lessons .lesson-container .content .hljs {
  margin: 20px 0;
}

.gs-container .content .gs-lessons .lesson-container .content .complete-notice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin: 5px 0;
}

.gs-container .content .gs-lessons .lesson-container .content .complete-notice .completed-svg {
  width: 36px;
  height: 36px;
  stroke: #2f9e44;
}

.gs-container .content .gs-lessons .lesson-container .content .complete-example {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #2f9e44;
  font-weight: 700;
  margin: 0 5px;
}

.gs-container .content .gs-lessons .lesson-container .content .complete-example .completed-svg {
  width: 24px;
  height: 24px;
}

.gs-container .content .gs-lessons .lesson-container .content h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  font-size: 32px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.gs-container .content .gs-lessons .lesson-container .content p {
  letter-spacing: 0px;
  line-height: 2;
  margin-bottom: 20px;
}

.gs-container .content .gs-lessons .lesson-container .content p .code-line {
  background-color: #e9ecef;
  padding: 2px 4px;
  color: #f06595;
  border-radius: 5px;
}

.gs-container .content .gs-lessons .lesson-container .content .p-with-svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gs-container .content .gs-lessons .lesson-container .content button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 20px auto;
  width: 500px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #2f9e44;
  color: #f8f9fa;
  font-weight: 700;
  font-size: 24px;
  padding: 10px 20px;
  -webkit-box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
}

.gs-container .content .gs-lessons .lesson-container .content button:hover {
  cursor: pointer;
}

.gs-container .content .gs-lessons .lesson-container .content button .completed {
  width: 30px;
  height: 30px;
  padding-left: 10px;
}
