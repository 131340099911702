.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    // background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('') center center no-repeat;
    background-color: rgb(23, 34, 53);
    background-size: cover;
    padding-bottom: 100px;

    h2 {
        color: aliceblue !important;
    }

    .header-greeting {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        width: 100%;
        height: 250px;

        h1, h4 {
            color: aliceblue;
        }
    }

    .new-course-card-container {
        display: flex;
        margin-top: 36px;

        .course-card {
            width: 250px;
            height: 300px;
            box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.5);
            transition: 300ms;
            margin: 0 10px;
            padding: 10px 20px;

            &:hover {
                cursor: pointer;
                text-shadow: 0 0 10px green;
                box-shadow: 0 0px 30px 0 rgba(255, 255, 255, 0.5);
            }

            .top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h4 {
                    color: aliceblue;
                    font-weight: 700;
                    font-size: 30px;
                    margin-top: 26px;
                    text-shadow: 0 0px 2px black;
                }

                .white-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                    background-color: rgba(255, 255, 255, 0.8);
                    border-radius: 200px;
                    margin-top: 44px;
                    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);

                    i {
                        text-shadow: 0 1px 1px black;
                    }

                    .fa-html5 {
                        color: chocolate;
                    }

                    .fa-css3-alt {
                        color: rgb(39, 77, 228);
                    }

                    .fa-js {
                        color: rgb(239,216,29);
                    }

                    .fa-react {
                        color: rgb(94,211,243);
                    }
                }
            }

            .bottom {
                margin-top: 24px;
                text-align: center;
                color: aliceblue;
                font-weight: 700;
                text-shadow: 0 0px 3px black;
            }
        }
    }

    .courses {
        .courses-container {
            display: flex;
            margin-top: 36px;

            .course-card {
                margin: 0 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 300px;
                width: 200px;
                box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                transition: 300ms;
                
                &:hover {
                    cursor: pointer;
                    transform: scale(1.025);
                }

                .lock-svg {
                    position: absolute;
                    width: 90px;
                    height: 90px;
                    z-index: 2;
                    fill: rgb(206, 206, 206);
                    stroke: black;
                }

                .course-card-content-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .i {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        background-color: #f8f9fa;
                        width: 100px;
                        height: 100px;
                        border-radius: 200px;
                        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1), inset 0 0 30px 0 rgba(0, 0, 0, 0.35);
                    }

                    p {
                        text-align: center;
                        margin-top: 20px;
                        font-size: 24px;
                        font-weight: 700;
                        color: #f8f9fa;
                        filter: drop-shadow(0 0 1px rgb(0,0,0));
                    }

                    .lock {
                        position: absolute;
                        .lock-svg {
                            position: relative;
                            top: 10px;
                            z-index: 2;
                        }
                    }
                }
            }

            .gs {
                background-color: #000;

                i {
                    font-size: 60px;
                    color: #000;
                    border-radius: 100px;
                    filter: drop-shadow(0 0 1px rgb(0, 0, 0));
                }
            }

            .html {
                background-color: chocolate;

                i {
                    font-size: 72px;
                    color: #d2691e;
                    border-radius: 100px;
                    filter: drop-shadow(0 0 1px rgb(0, 0, 0));
                }
            }

            .css {
                background-color: #264de4;

                i {
                    font-size: 72px;
                    color: #264de4;
                    border-radius: 100px;
                    filter: drop-shadow(0 0 1px rgb(0, 0, 0));
                }
            }

            .js {
                background-color: gold;

                i {
                    font-size: 72px;
                    color: gold;
                    border-radius: 100px;
                    filter: drop-shadow(0 0 1px rgb(0, 0, 0));
                }
            }

            .react {
                background-color: aqua;
                color: black;

                i {
                    font-size: 72px;
                    color: aqua;
                    border-radius: 100px;
                    filter: drop-shadow(0 0 1px rgb(0, 0, 0));
                }
            }

            .react.gated {
                filter: blur(2px);
                border-radius: 10px;
                // z-index: 1;
                width: 200px;
                height: 300px;
            }
        }
    }

    .upgrade-link {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: #69af86;
        border-bottom: 1px dotted #69af86;
        width: 70px;
        margin: 5px auto;

        .upgrade-svg {
            height: 14px;
            width: 14px;
            padding-left: 2px;
            transform: rotate(45deg);
        }

        &:hover {
            cursor: pointer;
        }
    }

    h1 {
        letter-spacing: -1px;
        padding-top: 120px;
        margin-bottom: 8px;
        font-size: 44px;
    }

    h2 {
        margin-top: 45px;
        letter-spacing: -0.5px;
    }

    h4 {
        font-size: 18px;
        letter-spacing: -0.2px;
        margin-bottom: 2px;
        font-weight: 500;
    }

    h1, h2, h4 {
        text-align: center;
        color: #212529;
    }
}