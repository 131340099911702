.gs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        padding-top: 85px;
        color: #343a40;
        width: 100%;
        
        h1 {
            font-size: 44px;
            margin-bottom: 20px;
            letter-spacing: -1px;
            text-align: center;
        }

        p {
            line-height: 1.7;
            font-weight: 500;

            .p-link {
                text-decoration: none;
                color: royalblue;
                border-bottom: 1px dotted royalblue;
            }

            img {
                width: 100%;
                height: auto;
                margin: 10px 0;
            }
        }

        .gs-lessons {
            display: grid;
            grid-template-columns: 1fr 4fr;
            gap: 8px;
            width: 100%;
            height: 450px;

            .menu {
                display: flex;
                flex-direction: column;
                background-color: #f8f9fa;
                height: 532px;
                border: 3px solid #343a40;

                h2 {
                    text-align: center;
                    border-bottom: 2px solid #343a40;
                }

                ul.menu-track {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    background: white;
                    border-left: 4px solid #f8f9fa;
                    border-right: 4px solid #f8f9fa;
                    border-top: 2px solid #343a40;

                    .active {
                        background-color:rgba(30, 44, 69, 1);
                        color:#e9ecef;
                    }

                    .li-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin: 5px 0;
                        box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 0.1);
                        z-index: 10;

                        &:hover {
                            cursor: pointer;
                        }

                        .completed {
                            width: 26px;
                            height: 26px;
                            padding-right: 10px;
                            stroke: #2f9e44;
                        }

                        .not-completed {
                            width: 26px;
                            height: 26px;
                            padding-right: 10px;
                            stroke: #fab005;
                        }
    
                        li {
                            display: flex;
                            align-items: center;
                            font-weight: 700;
                            font-size: 14px;
                            height: 50px;
                            padding-left: 10px;
                            z-index: 5;
                        }
                    }
                }
            }

            .lesson-container {
                background-color: #f8f9fa;
                border: 3px solid #1e2c45;

                .content {
                    display: flex;
                    flex-direction: column;
                    width: 1100px;
                    height: 500px;
                    padding: 16px 50px;
                    margin: 0 auto;
                    overflow-y: scroll;

                    iframe {
                        margin: 0 auto;
                        width: 100%;
                    }

                    pre {
                        background-color:#1e2c45!important;
                        width: 100%;
                        margin: 10px 0;
                        padding: 20px;

                        code {
                            white-space: pre;
                            margin: 10px 0;
                            color:#e9ecef;
                            background-color:#1e2c45;
                            width: 100%;
                        }
                    }

                    .hljs {
                        margin: 20px 0;
                    }

                    .complete-notice {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        font-size: 24px;
                        font-weight: 700;
                        margin: 5px 0;

                        .completed-svg {
                            width: 36px;
                            height: 36px;
                            stroke: #2f9e44;
                        }
                    }

                    .complete-example {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: #2f9e44;
                        font-weight: 700;
                        margin: 0 5px;

                        .completed-svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    h1 {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-size: 32px;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }

                    p {
                        letter-spacing: 0px;
                        line-height: 2;
                        margin-bottom: 20px;

                        .code-line {
                            background-color: #e9ecef;
                            padding: 2px 4px;
                            color: #f06595;
                            border-radius: 5px;
                        }
                    }

                    .p-with-svg {
                        display: flex;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 20px auto;
                        width: 500px;
                        height: 50px;
                        border: none;
                        border-radius: 10px;
                        background-color: #2f9e44;
                        color: #f8f9fa;
                        font-weight: 700;
                        font-size: 24px;
                        padding: 10px 20px;
                        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);

                        &:hover {
                            cursor: pointer;
                        }

                        .completed {
                            width: 30px;
                            height: 30px;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}